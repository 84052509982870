import React, { Component } from 'react';
import Slider from 'react-slick';
import LayoutWrapper from 'src/layouts/LayoutWrapper'
import './aboutPage.scss'
import Hero from 'components/Hero/Hero'
const linkedinIcon = require(`content/linkedin-icon.png`);
const mailIcon = require(`content/mail-icon.png`);
const banner = require(`content/banner.png`);
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class AboutPage extends Component {
	isMobile = false

	state = {
		memberVisible: null
	}

	openProfile = index => {
		const { memberVisible } = this.state

		console.log(memberVisible)
		console.log(index)

		this.setState({
			memberVisible: ( memberVisible === 0 && index === 0 ) ? null : !memberVisible ? index : null
		}) 
	}

	render() {
		if (typeof window != 'undefined') {
			this.isMobile = window.outerWidth <= 800 ? true : false
		}

		const {
			memberVisible
		} = this.state

		const {
			data,
			location
		} = this.props

		const settings = {
			dots: true,
			infinite: true,
			speed: 500,
			slidesToShow: 4,
			slidesToScroll: 4,
			autoplay: true,
			autoplaySpeed: 3000
		};

		const thisPageData = data.contentfulAboutPage

		const teamMembers = thisPageData.teamMembers

		return (
			<LayoutWrapper>
				<div className="about-page-container">
					<Hero
						backgroundImage={thisPageData.heroBackgroundImage ? thisPageData.heroBackgroundImage.file.url : null}
						title={thisPageData.heroTitle ? thisPageData.heroTitle.heroTitle : null}
						copy={thisPageData.heroCopy ? thisPageData.heroCopy.heroCopy : null}
                        location={location}
					/>
					<div className="twocolumn-container">
						<div className="container">
							<h1>{thisPageData.aboutUsTitle && thisPageData.aboutUsTitle}</h1>
							<ul>
								<li dangerouslySetInnerHTML={{__html: thisPageData.aboutUsColumn1 && thisPageData.aboutUsColumn1.aboutUsColumn1}} />
								<li dangerouslySetInnerHTML={{__html: thisPageData.aboutUsColumn2 && thisPageData.aboutUsColumn2.aboutUsColumn2}} />
							</ul>
						</div>
					</div>
					<div className="team-members-container">
						<div className="container">
							<h2>
								Our team
							</h2>
							<div className="main">
								<ul className="team-members">
									{teamMembers && teamMembers.map(({
										image,
										title,
										subtitle,
										copy,
										websiteUrl,
									}, memberIndex) => {

										let memberClass = ''

										if (memberVisible !== null) {
											if (memberIndex === memberVisible) {
												memberClass = 'active'
											} else {
												memberClass = 'hidden'
											}
										}

										let thisPersonModulo = ((memberIndex % 4) + 1)

										if (!thisPersonModulo) {
											thisPersonModulo = 4
										}

										let memberStyle

										if (!this.isMobile) {
											if (thisPersonModulo < 3) {
												memberStyle = {
													left: '100%',
													transform: 'translateX(8.2%)'
												}
											} else {
												memberStyle = {
													right: '100%',
													transform: 'translateX(-8.2%)',
													textAlign: 'right'
												}
											}
										}

										if (!image || !image.file) {
											return null
										}

										return (
											<li className={memberClass} key={memberIndex}>
												<div>
													<div className="person-profile" onClick={_ => { this.openProfile(memberIndex) }}>
														<img src={image.file.url} />
														<div>
															<h2>
																{title}
															</h2>
															<p>
																{subtitle}
															</p>
															<span className="toggle-profile">
																<span className={'fa-plus'}>
																	+
																</span>
																<span className={'fa-minus'}>
																	-
																</span>
															</span>
														</div>
													</div>
													<div className="person-content" style={memberStyle}>
														<p>
															{copy.copy}
														</p>
														<ul className="icon-row">
															<li>
																<a className="icon" href={websiteUrl}>
																	<img src={linkedinIcon} />
																</a>
															</li>
														</ul>
													</div>
												</div>
											</li>
										)
									})}
								</ul>
							</div>
						</div>
					</div>
					<div className="twocolumn-container">
						<div className="container">
							<h1>{thisPageData.shifraTitle && thisPageData.shifraTitle}</h1>
							<ul>
								<li dangerouslySetInnerHTML={{__html: thisPageData.shifraColumn1 && thisPageData.shifraColumn1.shifraColumn1}} />
								<li dangerouslySetInnerHTML={{__html: thisPageData.shifraColumn2 && thisPageData.shifraColumn2.shifraColumn2}} />
							</ul>
						</div>
					</div>
					<div className="partners-container">
						<div className="container">
							<h2>
								Partners
							</h2>

							<Slider {...settings}>
								{thisPageData.partners && thisPageData.partners.map((partner, partnerIndex) => (
									<div key={partnerIndex}>
										<img src={partner.image.file.url} />
									</div>
								)
								)}
							</Slider>
						</div>
					</div>
				</div>
			</LayoutWrapper>
		)
	}
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query aboutPageQuery($id: String!) {
    contentfulAboutPage(id: { eq: $id }) {
      heroBackgroundImage {
        file {
          url
        }
      }
      heroTitle {
		heroTitle
	}
	heroCopy {
		heroCopy
	}
	aboutUsTitle
	aboutUsColumn1 {
		aboutUsColumn1
	}
	aboutUsColumn2 {
		aboutUsColumn2
	}
      partners {
        image {
          file {
            url
          }
        }
      }
	shifraTitle
	shifraColumn1 {
		shifraColumn1
	}
	shifraColumn2 {
		shifraColumn2
	}
      teamMembers {
        image {
          file {
            url
          }
        }
        title 
        subtitle
        copy {
          copy
        }
        websiteUrl
      }
    }
  }
`;